import bootstrap from 'bootstrap';

const btns = document.querySelectorAll('.scrollTo');
const subjectField = document.querySelector('.js-subject');

function handleButtonClick(id) {
  const scrollToElement = document.getElementById(id);

  scrollToElement.scrollIntoView({block: "center", behavior: "smooth"});
}

btns.forEach(btn => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();

    handleButtonClick(e.target.dataset.id);

    if(!!e.target.dataset.subject) subjectField.value = e.target.dataset.subject;
  });
});

const tabsBtns = document.querySelectorAll('.js-tab-btn');
const tabsInner = document.querySelectorAll('.tabs-inner');
const tabsPreview = document.querySelector('.tabs-preview');

function handleButtonTabClick(index) {
  tabsPreview.classList.remove('active');

  tabsBtns.forEach(btn => btn.classList.remove('active'));
  tabsInner.forEach(btn => btn.classList.remove('active'));

  tabsBtns[index].classList.add('active');
  tabsInner[index].classList.add('active');
}

tabsBtns.forEach((btn, key) => {
  btn.addEventListener('click', (e) => {
    e.preventDefault();
    
    handleButtonTabClick(key);
  });
});

const carouselReviewsFade = document.querySelector('#carouselReviewsFade');

const carousel = new bootstrap.Carousel(carouselReviewsFade, {
  interval: 2000,
  wrap: false
});


